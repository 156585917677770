// Bootstrap
@import 'node_modules/bootstrap/scss/bootstrap';

// AOS
@import 'aos/dist/aos.css';

// Fonts
@import 'fonts';

// Variables
@import 'variables';

// Style
@import "xs/style";
@import "sm/style";
@import "md/style";
@import "lg/style";
@import "xl/style";
