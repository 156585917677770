@media screen and (min-width: 768px) {

    .headerSection {
        .alohaWrapperD {
            background-color: $white;
            padding: 25px;

            .logo {
                width: 320px;
            }

            .aloha {
                color: $magenta;
                animation: pulse 2s ease 3s infinite normal forwards;
                font-family: AktivGroteskEx_Trial_Bd, sans-serif;
                font-size: 38px;
                letter-spacing:1.6px;

                &:hover {
                    cursor: pointer;
                }
            }
        }
    }

    section {
        &.contentSection {
            height: calc(100vh - 127px);

            .logoWrapper {
                width: 650px;
                .sloganWrapper {
                    p {
                        font-size: 22px;
                    }
                }
            }

            .overlayContainer {
                display: block;
                width: 360px;
                right: -360px;
                transform: none;
                left: auto;
                height: calc(100vh - 127px);
                top: 127px;
                transition: right 0.2s;

                &.show {
                    right: 0;
                }

                .overlayWrapper {
                    .headlineWrapper {
                        margin-bottom: 60px;
                    }

                    .contactWrapper {
                        .linkWrapper {
                            margin-top: 30px;
                        }
                    }
                }
            }
        }
    }
}
