@font-face {
    font-family: 'AktivGroteskCd_Trial_Rg';
    font-style: normal;
    font-weight: 100;
    src: local(''),
    url('/fonts/AktivGroteskCd_Trial_Rg.woff2') format('woff2');
}

@font-face {
    font-family: 'AktivGroteskEx_Trial_Bd';
    font-style: normal;
    font-weight: 600;
    src: local(''),
    url('/fonts/AktivGroteskEx_Trial_Bd.woff2') format('woff2');
}

@font-face {
    font-family: 'AktivGroteskEx_Trial_XBd';
    font-style: normal;
    font-weight: 100;
    src: local(''),
    url('/fonts/AktivGroteskEx_Trial_XBd.woff2') format('woff2');
}

@font-face {
    font-family: 'CountryCafeCaps-XxJj_6';
    font-style: normal;
    font-weight: 100;
    src: local(''),
    url('/fonts/CountryCafeCaps-XxJj_6.woff2') format('woff2');
}
