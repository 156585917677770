html {
    overflow: hidden;
}

h1 {
    font-family: CountryCafeCaps-XxJj_6, sans-serif;
    color: $white;
    font-size: 60px;
    line-height: 1;
    letter-spacing:1.6px;

    &.magentaColor {
        color: $magenta;
    }
}
