@media screen and (min-width: 992px) {

    .headerSection {
        .alohaWrapperD {
            .arrow {
                width: 380px;
            }
        }
    }
}
