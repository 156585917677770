section {
    &.contentSection {
        height: 100vh;
        width:  100vw;
        background-image: url("/img/Background_Desktop.jpg");
        background-size: cover;
        background-repeat: no-repeat;

        .alohaWrapper {
            background-color: rgba(255, 255, 255, 0.9);
            padding-top: 15px;
            padding-bottom: 15px;
            margin: 0 auto;
            width: calc(100vw - 30px);

            &:hover {
                cursor: pointer;
            }

            .aloha {
                color: $magenta;
                animation: pulse 2s ease 3s infinite normal forwards;
                font-family: AktivGroteskEx_Trial_Bd, sans-serif;
                font-size: 38px;
                letter-spacing:1.6px;
            }

            @keyframes pulse {
                0% {
                    animation-timing-function: ease-out;
                    transform: scale(1);
                    transform-origin: center center;
                }

                10% {
                    animation-timing-function: ease-in;
                    transform: scale(0.91);
                }

                17% {
                    animation-timing-function: ease-out;
                    transform: scale(0.98);
                }

                33% {
                    animation-timing-function: ease-in;
                    transform: scale(0.87);
                }

                45% {
                    animation-timing-function: ease-out;
                    transform: scale(1);
                }
            }

            @keyframes shake {
                0%,
                100% {
                    transform: rotate(0deg);
                    transform-origin: 50% 50%;
                }

                10% {
                    transform: rotate(2deg);
                }

                20%,
                40%,
                60% {
                    transform: rotate(-3deg);
                }

                30%,
                50%,
                70% {
                    transform: rotate(3deg);
                }

                80% {
                    transform: rotate(-2deg);
                }

                90% {
                    transform: rotate(2deg);
                }
            }
        }

        .logoWrapper {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            padding-left: 15px;
            padding-right: 15px;
            .logo {
                width: 100%;
                margin-bottom: 30px;
            }

            .sloganWrapper {
                p {
                    font-family: AktivGroteskEx_Trial_Bd, sans-serif;
                    letter-spacing: 0.32px;
                }
            }
        }

        .overlayContainer {
            z-index: 10;
            position: fixed;
            width: calc(100vw - 30px);
            height: 100vh;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            display: none;

            .overlayWrapper {
                background-color: rgba(255, 255, 255, 0.9);
                padding: 15px 30px;

                .aloha {
                    margin-bottom: 60px;
                    color: $magenta;
                    font-family: AktivGroteskEx_Trial_Bd, sans-serif;
                    font-size: 38px;
                    letter-spacing:1.6px;
                }

                .headlineWrapper {
                    margin-bottom: 15px;
                    .headline {
                        color: $gold;
                        font-family: AktivGroteskEx_Trial_Bd, sans-serif;
                        letter-spacing: 4.8px;
                    }
                }

                .textWrapper {
                    .text {
                        color: $gold;
                        letter-spacing: 0.32px;

                        .highlight {
                            font-weight: bold;
                        }
                    }
                }

                .contactWrapper {
                    color: $magenta;
                    position: absolute;
                    bottom: 15px;

                    p {
                        letter-spacing: 0.32px;
                    }

                    a {
                        color: $magenta;
                        text-decoration: none;
                    }

                    a:hover {
                        text-decoration: underline;
                    }

                    .socialMediaWrapper {
                        margin-top: 15px;

                        a:first-child {
                            margin-right: 10px;
                        }
                        img {
                            width: 50px;
                        }
                    }

                    .linkWrapper {
                        margin-top: 15px;
                        a {
                            color: $magenta;
                            text-decoration: none;

                            &:hover {
                                text-decoration: underline;
                            }
                        }
                        span {
                            color: $magenta;
                            margin-left: 5px;
                            margin-right: 5px;
                        }
                    }
                }
            }
        }
    }
}
