@media screen and (min-width: 1200px) {

    .headerSection {
        .alohaWrapperD {
            .arrow {
                width: 490px;
            }
        }
    }

    section.contentSection {
        .logoWrapper {
            width: 560px;

            h1 {
                font-size: 80px;
            }

            p {
                font-size: 18px;
            }
        }

        .overlayContainer {
            .overlayWrapper {
                .headlineWrapper {
                    margin-bottom: 30px;
                }
            }
            .textWrapper {
                p {
                    font-size: 14px;
                }
            }
            .contactWrapper {
                p, a {
                    font-size: 14px;
                }
            }
        }
    }
}

@media screen and (min-width: 1600px) {
    section.contentSection {
        .logoWrapper {
            width: 650px;

            h1 {
                font-size: 110px;
            }

            p {
                font-size: 22px;
            }
        }

        .overlayContainer {
            .overlayWrapper {
                .headlineWrapper {
                    margin-bottom: 60px;
                }
            }
            .textWrapper {
                p {
                    font-size: 16px;
                }
            }
            .contactWrapper {
                p, a {
                    font-size: 16px;
                }
            }
        }
    }
}

